<template>
  <v-row class="ma-0">
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Your Email is already confirmed</div>
        <p class="mt-3">
          <v-row>
            <v-col cols="8" class="mx-auto opasity--text">An email had been confirmed recently. Use your email for Sign In.</v-col>
          </v-row>
        </p>
        <v-btn to="/login" text class="opasity--text"><v-icon left>mdi-arrow-left</v-icon> Back to Sign In</v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>
